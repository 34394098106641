<template>
  <v-app>
    <v-main>
      <logged-out-header-bar></logged-out-header-bar>
      <v-container fluid class="py-2 fill-height justify-center align-center">
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LoggedOutHeaderBar from '@/components/LoggedOutHeaderBar'
export default {
  name: 'LoggedOutLayout',
  components: { LoggedOutHeaderBar },
  data: () => ({}),
}
</script>

<style scoped></style>
