import router from '../router/index.ts'
import axios from 'axios'

const roleList = {
  sorter: 'ROLE_SORTER',
}

const user = JSON.parse(localStorage.getItem('user'))
const state = user
  ? {
      status: {
        loggedIn: true,
      },
      user: user,
    }
  : {
      status: {},
      user: null,
    }
const actions = {
  login({ commit }, { username, password }) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_BASE_URL + '/api/login-check', {
          username: username,
          password: password,
        })
        .then(response => {
          if (response.data.token) {
            commit('loginSuccess', { username, ...response.data })
            localStorage.setItem(
              'user',
              JSON.stringify({ username, ...response.data }),
            )
            resolve(response.data)
            router.push('/')
          }
        })
        .catch(error => {
          commit('loginFailure', error)
          reject(error)
        })
    })
  },
  loginSuccess({ commit }, { data }) {
    commit('loginSuccess', { ...data })
    localStorage.setItem('user', JSON.stringify({ ...data }))
  },
  logout({ commit }) {
    localStorage.removeItem('user')
    commit('logout')
    router.push({ name: 'Login' })
  },
  refreshToken({ commit, state }) {
    if (state.user === null || typeof state.user !== 'object') {
      return
    }
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_BASE_URL + '/api/login-refresh', {
          // eslint-disable-next-line @typescript-eslint/camelcase
          refresh_token: state.user.refresh_token,
        })
        .then(response => {
          if (response.data.token) {
            const token = response.data.token
            commit('saveToken', token)
            localStorage.setItem('user', JSON.stringify(state.user))
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('loginFailure', error)
          router.push({ name: 'Login' })
          reject(error)
        })
    })
  },
  isGranted({ state }, roles) {
    if (!state.user || !state.user.roles) {
      return false
    }
    if (typeof roles === 'string') {
      return state.user.roles.includes(roleList[roles])
    }
    for (const role of roles) {
      if (state.user.roles.includes(roleList[role])) {
        return true
      }
    }
    return false
  },
}

const mutations = {
  loginRequest(state, user) {
    state.status = {
      loggingIn: true,
    }
    state.user = user
  },
  loginSuccess(state, user) {
    state.status = {
      loggedIn: true,
    }
    state.user = user
  },
  saveToken(state, token) {
    state.user.token = token
  },
  setAddressesId(state, addressesId) {
    if (state.user === null || state.user.user.company === undefined) {
      return
    }
    state.user.user.company.addressesId = addressesId
  },
  setUsableLicencesId(state, usableLicencesId) {
    if (state.user === null || state.user.user.company === undefined) {
      return
    }
    state.user.user.company.usableLicencesId = usableLicencesId
  },
  loginFailure(state) {
    state.status = {}
    state.user = null
  },
  logout(state) {
    state.status = {}
    state.user = null
  },
}

const getters = {
  status: state => state.status,
}

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
