const state = {
  type: null,
  message: null,
  active: false,
}

const actions = {
  success({ commit }, message) {
    commit('success', message)
  },
  error({ commit }, message) {
    commit('error', message)
  },
  clear({ commit }) {
    commit('clear')
  },
  updateActive({ commit }, active) {
    commit('updateActive', active)
  },
}

const mutations = {
  success(state, message) {
    state.type = 'success'
    state.message = message
    state.active = true
  },
  error(state, message) {
    state.type = 'error'
    state.message = message
    state.active = true
  },
  clear(state) {
    state.type = null
    state.message = null
    state.active = false
  },
  updateActive(state, active) {
    state.active = active
  },
}

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations,
}
