const state = {
  data: {},
  setTimeouts: {},
}

const actions = {}

const mutations = {
  set(state, { key, value }) {
    state.data[key] = value
    clearTimeout(state.setTimeouts[key])
    state.setTimeouts[key] = setTimeout(() => {
      console.log('SET LOCALSTORAGE')
      localStorage.setItem(key, JSON.stringify(value))
    }, 100)
  },
}

const getters = {
  get: state => key => {
    if (state.data[key] !== undefined) {
      return state.data[key]
    }
    console.log('GET LOCALSTORAGE')
    state.data[key] = JSON.parse(localStorage.getItem(key))
    return state.data[key]
  },
}

export const storage = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
