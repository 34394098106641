import Vue from 'vue'
import Vuex from 'vuex'
import { alert } from '@/store/alert'
import { socket } from '@/store/socket'
import { canvas } from '@/store/canvas'
import { account } from '@/store/account'
import { storage } from '@/store/storage'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    documents: {},
    currentAddress: null,
    currentLicenceId: null,
  },
  mutations: {},
  actions: {},
  modules: { alert, socket, canvas, account, storage },
})
