import { io } from 'socket.io-client'
import Vue from 'vue'

const state = {
  socket: null,
  postId: null,
  bus: new Vue(),
  isClient: false,
}

const actions = {
  connect(context, postId) {
    if (context.state.socket !== null) {
      return
    }
    console.log('connexion au socket')
    const socket = io.connect(process.env.VUE_APP_SOCKET_BASE_URL)
    socket.on('connect', () => {
      socket.emit('post', postId)
    })
    context.commit('connect', { postId, socket })
  },
  send({ state }, { event, data }) {
    state.bus.$emit(event, data)
  },
  emit({ state }, { event, data }) {
    state.socket.emit(event, data)
  },
  async on({ state }, { event, callback }) {
    state.socket.on(event, callback)
  },
  disconnect({ state, commit }) {
    state.socket.disconnect()
    commit('connect', { postId: null, socket: null })
  },
}

const mutations = {
  connect(state, { postId, socket }) {
    state.postId = postId
    state.socket = socket
  },
  sendEvent(state, { event, data }) {
    state.bus.$emit(event, data)
  },
}

export const socket = {
  namespaced: true,
  state,
  actions,
  mutations,
}
