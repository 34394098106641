<template>
  <!--  remove "clipped" to let the left menu on the top left -->
  <!--  mini menu version : -->
  <v-navigation-drawer
    :value.sync="open"
    @input="$emit('update:open', $event)"
    :clipped="$vuetify.breakpoint.mdAndUp"
    app
    light
    :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
    temporary
  >
    <template v-if="$route.meta.client">
      <v-list>
        <v-list-item :to="{ name: 'Document' }" exact>
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Quitter</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-else>
      <div class="text-center">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{ companyName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-btn text small @click="$store.dispatch('account/logout')"
          >Se déconnecter</v-btn
        >
      </div>
      <v-divider class="mt-1"></v-divider>
      <v-list>
        <v-list-item :to="{ name: 'Document' }" exact>
          <v-list-item-content>
            <v-list-item-title>Éditeur photo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Sign' }" exact>
          <v-list-item-content>
            <v-list-item-title>Mode signature</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Licences' }" exact>
          <v-list-item-content>
            <v-list-item-title>Mes licences</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Device' }" exact>
          <v-list-item-content>
            <v-list-item-title>Cet appareil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'LeftMenu',
  props: {
    open: { default: null },
  },
  data: function() {
    return {}
  },
  computed: {
    companyName() {
      const user = this.$store.state.account.user
      if (
        user === null ||
        user.user === undefined ||
        user.user.company === undefined
      ) {
        return ''
      }
      return user.user.company.name
    },
    email() {
      const user = this.$store.state.account.user
      if (
        user === null ||
        user.user === undefined ||
        user.user.company === undefined
      ) {
        return ''
      }
      return user.user ? user.user.email : ''
    },
  },
  methods: {},
}
</script>

<!--suppress CssUnresolvedCustomProperty -->
<style scoped lang="scss">
.v-list-item--active {
  //background-color: var(--v-primary-lighten5);
}
</style>
