<template>
  <!--  remove "clipped" to let the left menu on the top left -->
  <!--  mini menu version : -->
  <v-navigation-drawer
    :value.sync="open"
    @input="$emit('update:open', $event)"
    app
    light
    :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
    temporary
    right
  >
    <v-list dense>
      <v-subheader>
        <div class="text-caption">
          <div class="font-weight-bold">Historique (5 derniers documents)</div>
          <div>Uniquement sur cet appareil</div>
        </div>
      </v-subheader>
      <v-list-item-group>
        <v-list-item
          exact
          v-for="index of Object.keys(documents).reverse()"
          :key="index"
          :to="{ name: 'Document', params: { id: index } }"
        >
          <v-list-item-content class="py-0">
            <div class="d-flex py-1">
              <div>
                <v-img
                  :src="documents[index].photoData.url"
                  width="90px"
                  :aspect-ratio="35 / 45"
                  contain
                ></v-img>
              </div>
              <div class="pl-2 d-flex justify-center flex-grow-1 flex-column">
                <div class="d-flex justify-end">
                  <v-btn
                    icon
                    @click.prevent.stop="deleteDocument(index)"
                    absolute
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <div
                  class="align-center justify-center d-flex flex-grow-1 flex-column"
                >
                  <div
                    class="success--text"
                    v-if="documents[index].documentValidated"
                  >
                    Terminé
                  </div>
                  <div class="warning--text" v-else>Brouillon</div>
                  <div
                    v-if="documents[index].creationDate"
                    class="text-caption"
                  >
                    {{ formatDate(documents[index].creationDate, true) }}
                  </div>
                </div>
              </div>
            </div>
            <v-divider></v-divider>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'HistoryMenu',
  props: {
    open: { default: null },
  },
  data: function() {
    return {}
  },
  computed: {
    documents() {
      return this.$store.state.documents
    },
  },
  methods: {
    deleteDocument(index) {
      if (
        !Object.prototype.hasOwnProperty.call(
          this.$store.state.documents,
          index,
        )
      ) {
        return
      }
      this.$delete(this.$store.state.documents, index)
      this.$root.set('documents', this.$store.state.documents)
    },
    formatDate(date, hour = false) {
      if (typeof date === 'string') {
        date = new Date(date)
      }
      return new Intl.DateTimeFormat('fr', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: hour ? 'numeric' : undefined,
        minute: hour ? 'numeric' : undefined,
      }).format(date)
    },
  },
}
</script>

<!--suppress CssUnresolvedCustomProperty -->
<style scoped lang="scss">
.v-list-item--active {
  //background-color: var(--v-primary-lighten5);
}
</style>
