<script>
/* eslint-disable @typescript-eslint/camelcase */
export default {
  name: 'validation',
  data() {
    return {
      unhandledErrors: {},
      errorsNbr: 0,
      form_validating: {},
    }
  },
  computed: {
    isValid() {
      return field => {
        return field.errors !== undefined && field.errors !== null
          ? !field.errors
          : null
      }
    },
  },
  methods: {
    formValidation(form, start = false) {
      if (start) {
        this.errorsNbr = 0
      }
      this.form_validating = form
      for (const fieldName in form) {
        if (
          !Object.prototype.hasOwnProperty.call(form, fieldName) ||
          fieldName === 'errors'
        ) {
          continue
        }
        const field = form[fieldName]
        if (Array.isArray(field)) {
          this.formValidation(field)
        } else if (field !== null && typeof field === 'object') {
          if (field.value !== undefined) {
            this.fieldValidation(field)
          } else {
            this.formValidation(field)
          }
        }
      }
    },
    fieldValidation(field) {
      if (field.errors && Object.keys(field.errors).length > 0) {
        this.$root.$emit('formError', false)
      }
      let tempRequired = false
      if (field.required_if && this.valueMatch(field.required_if)) {
        tempRequired = true
      }
      let matchAllRegex = -1
      if (field.regex && field.value.length && Array.isArray(field.regex)) {
        matchAllRegex = this.matchAllRegex(field.value, field.regex)
      }
      if (
        (field.required || tempRequired) &&
        (field.value === undefined || !field.value || field.value.length === 0)
      ) {
        this.addFieldError(field, 'Ce champ doit être rempli')
      } else if (field.minLength && field.value.length < field.minLength) {
        this.addFieldError(
          field,
          'Cette valeur est trop courte. Elle doit avoir ' +
            field.minLength +
            ' caractères ou plus',
        )
      } else if (field.maxLength && field.value.length > field.maxLength) {
        this.addFieldError(
          field,
          'Cette valeur doit avoir ' + field.maxLength + ' caractères ou moins',
        )
      } else if (field.min && field.value.length && field.value < field.min) {
        this.addFieldError(
          field,
          'Cette valeur doit être supérieur ou égal à ' + field.min,
        )
      } else if (field.max && field.value.length && field.value > field.max) {
        this.addFieldError(
          field,
          'Cette valeur doit être inférieur ou égal à ' + field.max,
        )
      } else if (
        field.regex &&
        field.value.length &&
        typeof field.regex === 'string' &&
        !field.value.match(field.regex)
      ) {
        this.addFieldError(
          field,
          field.regexMessage || "Cette valeur n'est pas valide",
        )
      } else if (matchAllRegex !== -1) {
        this.addFieldError(
          field,
          (Array.isArray(field.regexMessage)
            ? field.regexMessage[matchAllRegex]
            : field.regexMessage) || "Cette valeur n'est pas valide",
        )
      } else if (
        field.isEmail &&
        field.value.length &&
        !this.validateEmail(field.value)
      ) {
        this.addFieldError(field, "Cette adresse email n'est pas valide")
      } else {
        this.$set(field, 'errors', false)
      }
    },
    addFieldError(field, error) {
      if (typeof field !== 'object') {
        return false
      }
      // if (field.errors === undefined || typeof field.errors !== 'object' || (Array.isArray(field.errors.field) && field.errors.field.length === 0)) {
      // }
      this.$root.$emit('formError', true)
      this.$set(field, 'errors', [error])
    },
    handleServerErrors(data, path, name = 'main') {
      // console.log('handleServerErrors', path, name)
      if (path === undefined) {
        path = this.form
        this.$set(this, 'unhandledErrors', {})
      } else if (typeof path === 'string') {
        path = this.form
      }
      if (data.code !== undefined && typeof data.errors === 'object') {
        this.handleServerErrors(data['errors'], path, name)
        return
      }
      for (const fieldName in data) {
        if (!Object.prototype.hasOwnProperty.call(data, fieldName)) {
          continue
        }
        const field = data[fieldName]
        if (fieldName === 'errors') {
          if (Object.keys(path).length === 0 && path.constructor === Object) {
            if (this.unhandledErrors === undefined) {
              this.$set(this, 'unhandledErrors', {})
            }
            this.$set(this.unhandledErrors, name, field)
          } else {
            if (
              path['errors'] === undefined ||
              typeof path['errors'] !== 'object'
            ) {
              this.$set(path, 'errors', {})
            }
            this.$set(path.errors, name, field)
            // Array.prototype.push.apply(path.errors, field)
          }
          this.errorsNbr++
        } else if (fieldName === 'children') {
          let newPath = path
          if (
            path.value !== undefined &&
            (Array.isArray(path.value) || typeof path.value === 'object')
          ) {
            newPath = path.value
          }
          this.handleServerErrors(field, newPath, name)
        } else {
          // if (this.form[fieldName] === undefined) {
          //   // test this to avoid new field creation during validation
          //   return
          // }
          let newPath = path[fieldName]
          // console.log(newPath, fieldName, JSON.stringify(path))
          if (path.report_here === true) {
            newPath = path
          } else if (path[fieldName] === undefined) {
            newPath = path[fieldName] = {}
          }
          this.handleServerErrors(field, newPath, fieldName)
        }
      }
    },
    onFieldChange(field) {
      this.fieldValidation(field)
      if (field.fieldToFilterToDo) {
        field.fieldToFilter.choices = field.fieldToFilter.ChoicesForFilter.filter(
          x => x.id === field.value,
        )
      }
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    getErrors(errors) {
      return typeof errors === 'object' ? errors[Object.keys(errors)[0]] : []
    },
    valueMatch(arrayMatch) {
      console.log('arrayMatch', arrayMatch[0].value)
      if (Array.isArray(arrayMatch) && arrayMatch.length === 4) {
        const formParent = arrayMatch[0]
        if (
          formParent[arrayMatch[1]] === undefined ||
          !formParent[arrayMatch[1]][arrayMatch[2]]
        ) {
          return false
        }
        return formParent[arrayMatch[1]][arrayMatch[2]].value === arrayMatch[3]
      } else if (Array.isArray(arrayMatch) && arrayMatch.length === 2) {
        let variable = undefined
        try {
          variable = eval(arrayMatch[0])
        } catch (e) {
          /* aaa */
        }
        if (variable === undefined) {
          return false
        }
        return variable === arrayMatch[1]
      } else if (Array.isArray(arrayMatch) && arrayMatch.length === 3) {
        const formParent = arrayMatch[0]
        if (formParent === undefined || !formParent[arrayMatch[1]]) {
          return false
        }
        return formParent[arrayMatch[1]].value === arrayMatch[2]
      } else {
        console.log('valueMatch: Wrong argument type')
        return false
      }
    },
    formatDataToSend(form) {
      let sendable = {}
      for (const fieldName in form) {
        if (
          !Object.prototype.hasOwnProperty.call(form, fieldName) ||
          fieldName === 'errors' ||
          form[fieldName] === null ||
          (form[fieldName].send !== undefined && !form[fieldName].send) ||
          (Object.prototype.hasOwnProperty.call(form, 'not_send') &&
            Array.isArray(form.not_send) &&
            form.not_send.includes(fieldName)) ||
          fieldName === 'not_send'
        ) {
          continue
        }
        const field = form[fieldName]
        if (Array.isArray(field)) {
          if (fieldName === 'value') {
            sendable = this.formatDataToSend(field)
          } else {
            sendable[fieldName] = this.formatDataToSend(field)
          }
        } else if (typeof field === 'object') {
          if (field.to_send !== undefined && Array.isArray(field.to_send)) {
            sendable[fieldName] = {}
            for (const subName of field.to_send) {
              if (
                field[subName] !== undefined &&
                typeof field[subName] === 'object'
              ) {
                if (
                  field[subName].value !== undefined &&
                  !Array.isArray(field[subName].value)
                ) {
                  sendable[fieldName][subName] = field[subName].value
                } else {
                  sendable[fieldName][subName] = this.formatDataToSend(
                    field[subName],
                  )
                }
              } else if (
                field[subName] !== undefined &&
                field[subName] !== null
              ) {
                sendable[fieldName][subName] = field[subName]
              }
            }
          } else if (
            field.value !== undefined &&
            !Array.isArray(field.value) &&
            typeof field.value !== 'object'
          ) {
            sendable[fieldName] = field.value
          } else if (
            field.value !== undefined &&
            typeof field.value === 'object'
          ) {
            sendable[fieldName] = this.formatDataToSend(field.value)
          } else if (fieldName === 'value') {
            sendable = this.formatDataToSend(field)
          } else {
            sendable[fieldName] = this.formatDataToSend(field)
          }
        } else if (
          typeof field === 'string' ||
          typeof field === 'number' ||
          field === null
        ) {
          sendable[fieldName] = field
        } else {
          console.log('no type: ', field)
        }
      }
      return sendable
    },
    matchAllRegex(value, regexList) {
      if (!Array.isArray(regexList) || typeof value !== 'string') {
        return 0
      }
      for (const [key, regex] of regexList.entries()) {
        if (!value.match(regex)) {
          return key
        }
      }
      return -1
    },
    onKeyDown(field, e) {
      if (field.type === 'decimal') {
        this.onDecimal(e)
      } else if (field.type === 'number') {
        this.onNumber(e)
      }
    },
    onNumber(e) {
      const pos = e.target.selectionStart
      const oldVal = e.target.value
      const newVal = oldVal.slice(0, pos) + e.key + oldVal.slice(pos)
      if (e.key.length === 1 && e.ctrlKey === false) {
        if (newVal.match(/^\d*$/g) === null) {
          e.preventDefault()
        } else if (newVal.match(/,/g)) {
          const corrected = newVal.replace(/,/g, '.')
          this.$emit('input', corrected)
          e.target.value = corrected
          e.target.setSelectionRange(pos + 1, pos + 1)
          e.preventDefault()
        }
      }
    },
    onDecimal(e) {
      const pos = e.target.selectionStart
      const oldVal = e.target.value
      const newVal = oldVal.slice(0, pos) + e.key + oldVal.slice(pos)
      if (e.key.length === 1 && e.ctrlKey === false) {
        if (newVal.match(/^(\d{1,7}([.,]\d{0,2})?)?$/g) === null) {
          e.preventDefault()
        } else if (newVal.match(/,/g)) {
          const corrected = newVal.replace(/,/g, '.')
          this.$emit('input', corrected)
          e.target.value = corrected
          e.target.setSelectionRange(pos + 1, pos + 1)
          e.preventDefault()
        }
      }
    },
  },
  mounted() {
    this.$root.$on('formError', state => {
      this.errorsNbr = state
        ? this.errorsNbr + 1
        : this.errorsNbr > 0
        ? this.errorsNbr - 1
        : 0
    })
  },
}
</script>

<style scoped></style>
