<template>
  <v-app>
    <LeftMenu :open.sync="leftMenuOpen"></LeftMenu>

    <HeaderBar
      @switch-left-menu="leftMenuOpen = !leftMenuOpen"
      @switch-history-menu="historyMenuOpen = !historyMenuOpen"
    ></HeaderBar>
    <history-menu :open.sync="historyMenuOpen"></history-menu>
    <v-main>
      <v-container fluid class="d-block fill-height">
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import HeaderBar from '../components/HeaderBar'
import LeftMenu from '@/components/LeftMenu'
import HistoryMenu from '@/components/HistoryMenu'

export default {
  name: 'DefaultLayout',
  components: { HistoryMenu, LeftMenu, HeaderBar },
  data: () => ({
    leftMenuOpen: null,
    historyMenuOpen: null,
  }),
}
</script>

<style scoped></style>
