<template>
  <div>
    <nav class="navbar container-fluid fixed-top" style="background-color:#1f3763; color: white;" position="left center" width="5rem" >
    <h4 class="mx-auto">CDAP</h4>
   </nav>
  <div>
    <v-card width="400px">
      <v-card-title>Connexion</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form @submit="submitLogin">
          <text-field
            type="email"
            label="Email"
            :field="loginForm.username"
          ></text-field>
          <text-field
            type="password"
            label="Mot de passe"
            :field="loginForm.password"
          ></text-field>
          <div class="d-flex">
            <v-btn
              color="success"
              class="ml-auto"
              type="submit"
              :loading="loading"
            >
              Se connecter
            </v-btn>
          </div>
        </v-form>
        <div v-if="loginError" class="error--text text-center mt-2">
          {{ loginError }}
        </div>
        <div>
          <router-link :to="{ name: 'ForgetPassword' }"
            >Mot de passe oublié</router-link
          >
        </div>
        <div>
          <router-link :to="{ name: 'Register' }">Créer un compte</router-link>
        </div>
      </v-card-text>
    </v-card>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import TextField from '@/components/form/TextField'
import Validation from '@/components/mixins/Validation'
export default {
  name: 'Login',
  components: { TextField },
  mixins: [Validation],
  data() {
    return {
      loginForm: {
        username: {
          value: '',
          required: true,
        },
        password: {
          value: '',
          required: true,
        },
      },
      loading: false,
      unhandledErrors: {},
      loginError: null,
    }
  },
  methods: {
    submitLogin(e) {
      if (e) {
        e.preventDefault()
      }
      this.formValidation(this.loginForm, true)
      if (this.errorsNbr > 0) {
        return
      }
      this.loading = true
      this.loginError = false
      axios
        .post(process.env.VUE_APP_API_BASE_URL + '/api/login-check', {
          username: this.loginForm.username.value,
          password: this.loginForm.password.value,
        })
        .then(response => {
          if (response.data.token) {
            this.$store.dispatch('account/loginSuccess', {
              username: this.loginForm.username.value,
              data: response.data,
            })
            this.$router.push({ name: 'Login' })
          } else if (response.data.emailNotVerified) {
            return this.$router.push({
              name: 'ConfirmEmail',
              query: { email: this.loginForm.username.value },
            })
          } else if (response.data.accountNotVerified) {
            return this.$router.push({
              name: 'WaitingApproval',
            })
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.loginError = 'Identifiants incorrects'
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  beforeCreate() {
    if (this.$store.state.account.status.loggedIn) {
      //this.$router.push({ name: 'Home' })
      this.$router.push({ name: 'Login' })
    }
  },
}
</script>

<style scoped></style>

