<template>
<div>
    <component :is="layout" v-show="$route.name">
      <router-view :key="random"></router-view>
      <v-snackbar
        :value="alert.active"
        @input="$store.dispatch('alert/updateActive', $event)"
        :color="alert.type"
      >
        {{ alert.message }}
        <v-btn
          dark
          text
          @click.native="$store.dispatch('alert/updateActive', false)"
          >OK
        </v-btn>
      </v-snackbar>
    </component>
    <help v-model="helpDialog"></help>
  </div>
</template>

<script>
import Vue from 'vue'
import Help from '@/components/Help'

const defaultLayout = 'default'

export default Vue.extend({
  name: 'App',
  components: { Help },
  data: () => ({
    random: 0,
    checkConnexionTimeout: null,
    helpDialog: false,
  }),

  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    },
    alert() {
      return this.$store.state.alert
    },
  },
  methods: {
    checkConnexion() {
      this.$store.dispatch('account/refreshToken')
      this.checkConnexionTimeout = setTimeout(this.checkConnexion, 120000)
    },
  },
  mounted() {
    this.$root.$on('reload', () => {
      this.random++
    })
    this.checkConnexion()
    this.$root.$on('help', () => {
      this.helpDialog = true
    })
  },
  beforeDestroy() {
    clearTimeout(this.checkConnexionTimeout)
  },
})
</script>
