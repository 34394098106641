const state = {
  canvasData: null,
}

const actions = {
  signatureFinished(context, { canvasData }) {
    context.commit('canvasData', canvasData)
  },
}

const mutations = {
  canvasData(state, canvasData) {
    state.canvasData = canvasData
  },
}

export const canvas = {
  namespaced: true,
  state,
  actions,
  mutations,
}
