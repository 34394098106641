<template>
  <v-card v-bind="$attrs" class="d-flex flex-column photo-editor-card">
    <v-card-title class="text-overline pt-1">
      Photo
      <v-progress-circular
        class="mx-1"
        indeterminate
        size="20"
        width="2"
        v-show="renderLoading && !finished"
      ></v-progress-circular>
      <v-spacer></v-spacer>
      <v-file-input
        v-model="file"
        ref="fileInput"
        hide-input
        prepend-icon=""
        accept="image/jpeg,image/png"
        style="max-width:2px"
        @input="fileChange"
        @change="fileChange"
      ></v-file-input>
      <div
        v-if="file"
        class="text-caption text--secondary mr-2 text-truncate"
        style="max-width: 190px"
      >
        {{ file.name }}
      </div>
      <v-btn
        x-small
        color="primary"
        @click="$refs.fileInput.$refs.input.click()"
        v-if="file"
        :disabled="disabled"
      >
        <v-icon left small>
          mdi-swap-vertical
        </v-icon>
        Remplacer la photo
      </v-btn>
    </v-card-title>
    <v-card-text
      class="text-center photo-editor-card-content pb-2"
      v-if="!finished"
      v-show="file"
    >
      <div
        style="position:relative;height: 100%"
        class="d-flex flex-column"
        v-resize="resize"
      >
        <div style="height: 100%">
          <canvas
            id="photo-canvas"
            ref="canvas"
            style="max-width:100%;"
          ></canvas>
        </div>
      </div>
    </v-card-text>
    <v-card-text v-if="file && !finished" class="pt-0 pb-1 d-flex">
      <v-icon small>mdi-crop-rotate</v-icon>
      <v-slider
        v-model="rotation"
        min="-90"
        max="90"
        hide-details
        thumb-label
        :disabled="disabled"
      ></v-slider>
    </v-card-text>
    <v-card-text
      v-else
      class="pt-0 pb-5 d-flex justify-center align-center photo-editor-placeholder-box"
      :class="{ finished: finished }"
      style="max-height: 100%;overflow: hidden;height: 100%"
      @click="!finished && $refs.fileInput.$refs.input.click()"
    >
      <canvas
        height="450"
        width="350"
        style="height: 100%;max-height: 100%"
        class="photo-editor-placeholder"
      >
      </canvas>
      <div v-if="!finished" style="position: absolute">
        <v-icon>mdi-plus-box-multiple</v-icon>
        Importer une photo
      </div>
      <div v-else style="position: absolute;" class="success--text">
        <v-icon color="success">mdi-check-circle</v-icon>
        Document terminé
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import 'cropperjs/dist/cropper.min.css'
import Cropper from 'cropperjs'

export default {
  name: 'PhotoEditor',
  props: {
    settings: { type: Object },
    data: { type: Object },
    disabled: { type: Boolean },
    finished: { type: Boolean },
  },
  data() {
    return {
      file: null,
      caman: null,
      originalCaman: null,
      cropper: null,
      croppedUrl: null,
      cropTimeout: null,
      camanTimeout: null,
      lastPhotoSettings: {
        brightness: 0,
        contrast: 0,
        gamma: 1,
        saturation: 0,
        exposure: 0,
      },
      brightnessLayer: null,
      contrastLayer: null,
      renderLoading: false,
      maxCanvasHeight: '100%',
      rotation: 0,
    }
  },
  methods: {
    fileChange() {
      if (this.caman !== null) {
        document.querySelector('#photo-canvas').removeAttribute('data-caman-id')
      }
      this.reset()
      this.$nextTick(this.initCaman)
    },
    rerenderImage() {
      this.renderLoading = true
      this.caman.render(() => {
        this.cropper.replace(this.caman.toBase64(), true)
        this.$nextTick(this.emitPreview)
        this.renderLoading = false
        if (this.disabled) {
          this.cropper.disable()
        }
      })
    },
    emitPreview() {
      if (this.finished) {
        return
      }
      clearTimeout(this.cropTimeout)
      this.cropTimeout = setTimeout(async () => {
        const width = 35 * 11.8
        const height = 45 * 11.8
        this.croppedUrl = this.cropper
          .getCroppedCanvas({
            width: width,
            height: height,
            imageSmoothingQuality: 'high',
          })
          .toDataURL()
        this.$emit('croppedUrl', {
          url: this.croppedUrl,
          originalPhotoUrl: await this.fileToB64(this.file),
          width: width,
          height: height,
          data: this.cropper.getData(),
        })
      }, 300)
    },
    resize() {
      if (this.cropper === null) {
        return
      }
      try {
        this.cropper.zoom(-1)
      } catch (e) {
        return
      }
    },
    initCropper(data) {
      const canvas = document.querySelector('#photo-canvas')
      this.cropper = new Cropper(canvas, {
        aspectRatio: 35 / 45,
        crop: this.emitPreview,
        viewMode: 2,
        dragMode: 'move',
        responsive: true,
        guides: false,
        background: false,
        movable: false,
        autoCropArea: 1,
        // zoomable: false,
        zoomOnWheel: false,
        minCropBoxHeight: 100,
        restore: true,
        rotatable: !this.disabled,
        scalable: !this.disabled,
        modal: false,
      })
      if (data) {
        setTimeout(() => this.cropper.setData(data), 100)
      }
      canvas.addEventListener('ready', () => {
        this.updateSettings(true)
        this.renderLoading = false
      })
    },
    initCaman(blob, data) {
      const URL = window.URL || window.webkitURL
      let url
      if (blob) {
        url = URL.createObjectURL(blob)
      } else {
        url = URL.createObjectURL(this.file)
      }
      // eslint-disable-next-line no-undef
      this.caman = Caman('#photo-canvas', url, () => {
        URL.revokeObjectURL(URL)
        if (this.cropper !== null) {
          this.cropper.destroy()
        }
        setTimeout(() => this.initCropper(data), 100)
      })
    },
    reset() {
      this.$emit('reset')
      this.rotation = 0
    },
    async loadPhotoData(photoData) {
      // const blob = this.b64toBlob(photoData.url)
      if (!photoData || Object.keys(photoData).length === 0) {
        this.renderLoading = false
        return
      }
      console.log('photoData', photoData)
      const blob = await (await fetch(photoData.originalPhotoUrl)).blob()
      this.initCaman(blob, photoData.data)
      this.file = blob
    },
    fileToB64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    updateSettings(now = false) {
      if (this.caman === null) {
        return
      }
      const defaultSettings = {
        brightness: 0,
        contrast: 0,
        gamma: 1,
        saturation: 0,
        exposure: 0,
      }
      const settings = Object.assign({}, defaultSettings, this.settings)
      clearTimeout(this.camanTimeout)
      this.camanTimeout = setTimeout(
        () => {
          this.caman.reset()
          if (settings.brightness !== defaultSettings.brightness) {
            this.caman.brightness(settings.brightness)
          }
          if (settings.contrast !== defaultSettings.contrast) {
            this.caman.contrast(settings.contrast)
          }
          if (settings.gamma !== defaultSettings.gamma) {
            this.caman.gamma(settings.gamma)
          }
          if (settings.saturation !== defaultSettings.saturation) {
            this.caman.saturation(settings.saturation)
          }
          if (settings.exposure !== defaultSettings.exposure) {
            this.caman.exposure(settings.exposure)
          }
          this.rerenderImage()
          this.lastPhotoSettings = settings
          console.log('update settinggs')
        },
        now ? 1 : 300,
      )
    },
  },
  watch: {
    settings: {
      deep: true,
      handler() {
        if (this.finished) {
          return
        }
        this.updateSettings()
      },
    },
    data: {
      deep: true,
      handler(value) {
        if (value === null || this.finished) {
          return
        }
        this.renderLoading = true
        this.loadPhotoData(value)
      },
    },
    rotation(value) {
      this.cropper.rotateTo(value)
      this.cropper.zoom(-1)
    },
    disabled(value) {
      if (this.cropper === null) {
        return
      }
      if (value) {
        this.cropper.disable()
      } else {
        this.cropper.enable()
      }
    },
  },
  mounted() {
    if (this.data && !this.finished) {
      this.loadPhotoData(this.data)
    }
  },
}
</script>

<style scoped lang="scss">
#photo-canvas {
  height: 100%;
  max-height: 100%;
  position: relative;
}

.photo-editor-card {
  max-height: calc(100vh - 72px);
  .photo-editor-card-content {
    max-height: 100%;
    overflow: hidden;
    height: 100%;
    ::v-deep {
      .cropper-view-box {
        &:after {
          content: '';
          width: 100%;
          height: 100%;
          background-image: url('/img/mask.png');
          background-position: center;
          background-size: contain;
          top: 0;
          left: 0;
          position: absolute;
          display: inline-block;
          z-index: 0;
        }
      }
    }
  }
  .photo-editor-placeholder-box {
    .photo-editor-placeholder {
      background-color: #dadedf;
    }
    &:hover:not(.finished) {
      cursor: pointer;
      .photo-editor-placeholder {
        background-color: #adadad;
      }
    }
  }
}

::v-deep {
  .cropper-point {
    height: 10px;
    width: 10px;
    background-color: var(--v-primary-base);
    &.point-n,
    &.point-s {
      margin-left: -5px;
    }
  }
  .cropper-line {
    background-color: var(--v-primary-base);
  }
  .cropper-view-box {
    outline: var(--v-primary-base) solid 1px;
  }
}
</style>
