import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Default from '@/layouts/DefaultLayout'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import LoggedOutLayout from '@/layouts/LoggedOutLayout'

Vue.component('default-layout', Default)
Vue.component('logged-out-layout', LoggedOutLayout)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  methods: {
    get(key: never) {
      return store.getters['storage/get'](key)
    },
    set(key: never, value: never) {
      return store.commit('storage/set', { key: key, value: value })
    },
  },
}).$mount('#app')
