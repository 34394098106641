<template>
  <v-text-field
    v-model="field.value"
    :error-messages="getErrors(field.errors)"
    @change="onFieldChange(field)"
    @keydown="onKeyDown(field, $event)"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-text-field>
</template>

<script>
import Validation from '../mixins/Validation'

export default {
  name: 'TextField',
  mixins: [Validation],
  props: {
    field: {
      type: Object,
    },
  },
}
</script>

<style scoped></style>
