<template class="container-fluid">
<body id="page-top">
    <!-- Navigation-->
    <nav class="navbar navbar-expand-lg navbar-light shadow-sm" id="mainNav">
        <div class="container-fluid px-5" style="color:aliceblue">
            <a class="navbar-brand fw-bold" href="#page-top">CDAP</a>
            <a class="navbar-brand"  href="#fonctionnalités">Fonctionnalités</a>
            <a class="navbar-brand" href="#tarifs">Tarifs</a>
            <a class="navbar-brand" href="#contact">Contact</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                Menu
                <i class="bi-list"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarResponsive">
                <ul class="navbar-nav ms-auto me-4 my-3 my-lg-0">
                    <li class="nav-item"><a class="nav-link navbar-brand me-lg-3" href="./login">Se connecter</a></li>
                    <li class="nav-item"><a class="nav-link navbar-brand me-lg-3" href="./register">S'inscrire</a></li>
                </ul>
                
            </div>
        </div>
    </nav>
    
    <!-- Mashead header-->
    <header class="masthead" id="B">
        <div class="container-fluid px-5 back">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <!-- Mashead text and app badges-->
                    <div class="mb-5 mb-lg-0 text-center text-lg-start">
                        <h1 class="display-1 lh-1 mb-3">Vendez des photos dématérialisées.</h1>
                        <p class="lead fw-normal mb-5">Avec un logiciel moderne, agréé par l'ANTS,<br> adapté aux photographes d'aujourd'hui!</p>
                      <!--  <div class="d-flex flex-column flex-lg-row align-items-center">
                        <button type="button" class="btn btn-outline-primary" style="background-color: #2c4b8a; color: white; padding-left: 50px; padding-right: 50px; padding-top: 10px; padding-bottom: 10px; font-weight: bold;">TESTEZ GRATUITEMENT</button>
                        </div>-->
                    </div>
                </div>
                <div class="col-lg-6">
                    <!-- Masthead device mockup feature-->
                    <div>
                        <img src="../assets/template/assets/img/cap1.png" alt="" width="100%" height="100%">
                    </div>
                </div>
            </div>
        </div>
    </header>
   <!-- <div class="container-fluid">
  <svg viewBox="0 70 500 60" preserveAspectRatio="none" class="svg1">
    <rect x="0" y="0" width="500" height="500" style="stroke: none;" />
    <path d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z" style="stroke: none;"></path>
  </svg>
</div>-->
    <!-- App features section-->
    <section id="fonctionnalités">
        <div class="container-fluid">
            <h1 style="text-align: center; line-height: 1.5em; font-weight: bold; margin-bottom: 50px; color: #1F3763;">Conçu pour faciliter la vie des <br>photographes, et celle de leurs clients</h1>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                            <div class="features-icons-icon d-flex"><img src="../assets/template/assets/img/photograph.png" height="100%" width="100%" alt="..."></div>
                            <h3 class="text-center">Code ephoto en 3 clics</h3>
                            <p class="lead mb-0 text-center">
Importez les clichés de votre appareil photo (y compris en Bluetooth, WiFi et via un câble) puis 3 clics suffisent pour générer votre planche photo.</p>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                            <div class="features-icons-icon d-flex"><img src="../assets/template/assets/img/flex.png" height="100%" width="100%" alt="..."></div>
                            <h3 class="text-center">Flexibilité inédite</h3>
                            <p class="lead mb-0 text-center">Toutes les tablettes ou smartphones peuvent être utilisés pour récolter la signature de vos clients. Plus besoin d'investir dans une borne fixe, trop chère et qui vous rend captif chez un fournisseur.</p>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="features-icons-item mx-auto mb-0 mb-lg-3">
                            <div class="features-icons-icon d-flex"><img src="../assets/template/assets/img/refl.png" height="100%" width="75%" alt="..."></div>
                            <h3 class="text-center">Sans limite... C'est encore mieux</h3>
                            <p class="lead mb-0 text-center">Générez un nombre illimité d'ephoto pour le permis de conduire, le titre de séjour et dès cet été pour la carte nationale d'identité (CNI) et le passeport.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid px-5" style="margin-top:120px; background-color:#f8f9fa;">
                <div id="A">
                    <div class="row gx-5 align-items-center">
                    <div class="col-lg-8 order-lg-1 mb-5 mb-lg-0">
                        <div class="container-fluid px-5">
                            <div class="row gx-5">
                                <div class="col-md-6 mb-5">
                                    <!-- Feature item-->
                                    <div class="text-center">
                                        <i class="bi-phone icon-feature text-gradient d-block mb-3"></i>
                                        <h3 class="font-alt">Compatibilité sur tablette et ordinateur</h3>
                                        <p class="text-muted mb-0">Connectez-vous à travers votre tablette ou votre machine !</p>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-5">
                                    <!-- Feature item-->
                                    <div class="text-center">
                                        <i class="bi-camera icon-feature text-gradient d-block mb-3"></i>
                                        <h3 class="font-alt">Retouche en ligne</h3>
                                        <p class="text-muted mb-0">Filtrez, redimensionnez et modifiez vos photos rapidemen!</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-5 mb-md-0">
                                    <!-- Feature item-->
                                    <div class="text-center">
                                        <!--<i class="bi-gift icon-feature text-gradient d-block mb-3"></i>-->
                                        <img class="bi-gift icon-feature text-gradient d-block mb-3" src="../assets/template/assets/img/ants.png" alt="..." width="30%" height="30%" style="margin-left: 110px;"/>
                                        <h3 class="font-alt">Conforme à ANTS</h3>
                                        <p class="text-muted mb-0">Offrez à vos clients une photo 100% conforme, avec une facilité remarquable !</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <!-- Feature item-->
                                    <div class="text-center">
                                        <i class="bi-patch-check icon-feature text-gradient d-block mb-3"></i>
                                        <h3 class="font-alt">Signature</h3>
                                        <p class="text-muted mb-0">Créez une signature, puis ajoutez-la !</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 order-lg-0">
                        <!-- Features section device mockup-->
                            <div class="features-device-mockup">
                                <img src="../assets/template/assets/img/id7.png" alt="..." width="80%" height="100%">
                            </div>
                    </div>
                </div>
                </div>
                
        </div>
    </section>
    <!-- Quote/testimonial aside-->
    <!--
    <aside class="text-center bg-gradient-primary-to-secondary">
        <div class="container px-5">
            <div class="row gx-5 justify-content-center">
                <div class="col-xl-8">
                    <div class="h2 fs-1 text-white mb-4">"La solution la plus avantageuse du marché!"</div>
                    <img src="../assets/template/assets/img/tnw-logo.svg" alt="..." style="height: 3rem" />
                </div>
            </div>
        </div>
    </aside> -->
    <section class= "container-fluid " id="tarifs" style="background-color: #170034; color:azure">
        <div>
            <div  class="row justify-content-md-center" style="text-align: left; ">
               <!-- <div class="col-sm" style="text-align: center; padding-top: 50px;">
                    <p><span style="font-family:New Century Schoolbook, TeX Gyre Schola, serif; font-weight:bold; font-size:30px;">Offre à durée limitée :</span><br>
Inscrivez-vous aujourd'hui et profitez gratuitement <br>de CDAP pendant 30 jours, puis bénéficiez d'un tarif<br> exceptionnel, sans limite de durée.
                    </p>
                </div> -->
                <div class="col-sm" style="text-align: center;line-height: 2em; padding-top: 70px; padding-right: 20px;">
                    <p><span style="font-family:New Century Schoolbook, TeX Gyre Schola, serif; font-weight:bold; font-size:30px;">La solution la plus avantageuse du marché!</span><br></p><p style="font-family:New Century Schoolbook, TeX Gyre Schola, serif; font-size:15px;">Vous serez présent sur le site officiel des photographes <br/>agréés et obtiendrez une visibilité accrue.Inscrivez-vous <br/>aujourd'hui et bénéficiez d'un tarif exceptionnel.
                    </p>
                </div>
                <div class="col-sm" style="line-height: 3em; padding: 50px;">
                    <p class="text-center" style="font-family:New Century Schoolbook, TeX Gyre Schola, serif; font-weight:bold; font-size:90px; ">14.90€/ <span style="font-family:New Century Schoolbook, TeX Gyre Schola, serif; font-weight:bold; font-size:30px;">mois</span></p>         
                   <!-- <i class="bi bi-check-lg"><span style="padding-left: 10px;">E-Photos illimitées</span></i>
                    <span><i class="bi bi-check-lg" style="margin-left:10px;"><span style="padding-left: 10px;">Engagement au mois</span></i><br/></span>
                    <i class="bi bi-check-lg"><span style="padding-left: 10px;">Support et mises à jour</span></i>
                    <span><i class="bi bi-check-lg" style="margin-left:10px;"><span style="padding-left: 10px;">Aucun frais d'inscription</span></i><br/></span>
                    <i class="bi bi-check-lg"><span style="padding-left: 10px;">Aucun "droit d'entrée"</span></i>
                    <i class="bi bi-check-lg"><span style="padding-left: 10px;">Aucun coût d'utilisation</span></i><br>
                    <i class="bi bi-check-lg"><span style="padding-left: 10px;">Aucune borne à acheter</span></i><br>-->
                    <div class="row">
                        <div class="col-md-6">
                            <i class="bi bi-check-lg"><span style="padding-left: 10px;">E-Photos illimitées</span></i><br/>
                            <i class="bi bi-check-lg"><span style="padding-left: 10px;">Engagement au mois</span></i><br/>
                            <i class="bi bi-check-lg"><span style="padding-left: 10px;">Aucun frais d'inscription</span></i><br/>
                            <i class="bi bi-check-lg"><span style="padding-left: 10px;">Support et mises à jour</span></i>
                        </div>
                        <div class="col-md-6">
                            <i class="bi bi-check-lg"><span style="padding-left: 10px;">Aucun "droit d'entrée"</span></i><br/>     
                            <i class="bi bi-check-lg"><span style="padding-left: 10px;">Aucun coût d'utilisation</span></i><br>
                    <i class="bi bi-check-lg"><span style="padding-left: 10px;">Aucune borne à acheter</span></i>                  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!-- Basic features section-->
   <!-- <section class="bg-light">
        <div class="container-fluid px-5">
            <div class="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                <div class="col-12 col-lg-5">
                    <h4 class="display-4 lh-1 mb-4">Des innovations qui changent la donne</h4>
                    <p class="lead fw-normal text-muted mb-5 mb-lg-0">Laissez optionnellement vos clients à saisir leur adresse email. Ils recevront une copie de leur code ephoto et vous pourrez garder le contact avec eux.</p>
                </div>
                <div class="col-sm-8 col-md-6">
                    <div class="px-5 px-sm-0"><img class="img-fluid square-circle" src="../assets/template/assets/img/cap1.png" alt="..." /></div>
                </div>
            </div>
        </div>
    </section> -->
    
    <!-- Footer-->
    <footer class="py-5" style="background-color:#1c1c1c">
        <section id="contact">
           <!-- <div class="row" style="color: aliceblue; padding-bottom: 50px;font-family:New Century Schoolbook, TeX Gyre Schola, serif; line-height: 2em; margin-left: 30px;">
                <div class="col" style="margin-bottom: 60px;">Vous avez une question ? <br>Quelque-chose ne vous convient pas ? <br>Vous souhaitez un rendez-vous téléphonique ?</div>
                <div class="col"><span style="font-weight:bold; font-size:30px;">Contactez-nous : </span><br><a href="">contact@cdap.photo</a></div>
            </div> -->
            <div class="row text-center" style="color: aliceblue; padding-bottom: 20px;font-family:New Century Schoolbook, TeX Gyre Schola, serif; line-height: 2em;">
                
                <div class="col"><span style="font-weight:bold; font-size:30px; ">Contactez-nous : </span><br><a href="" style="color:#8ba9ee">contact@cdap.photo</a></div>
            </div> 
            <hr style="color: aliceblue;">
            <div class="container-fluid text-center px-5" style="margin-top:70px;">
                <div class="text-white-50 small">
                    <div class="mb-2">&copy; Your Website 2022. All Rights Reserved.</div>
                    <a href="/CGU.htm" style="color:#8ba9ee">Conditions générales d'utilisation</a>
                    <span class="mx-1">&middot;</span>
                    <a href="/Protection_donnees.htm" style="color:#8ba9ee"> Politique de confidentialité</a>
                    <span class="mx-1">&middot;</span>
                    <a href="#!" style="color:#8ba9ee">FAQ</a>
                </div>
            </div>
        </section>
    </footer>
    
    
    <!-- Feedback Modal-->
    <div class="modal fade" id="feedbackModal" tabindex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            </div>
        </div>
    </div>
</body>
</template>
<script>
    export default {
        name:'Master',
    };
</script>
<style>
.svg1 {
  width: 100%;
  height: 100%;
}
.svg1 path {
  fill: #cee3fe;
}
#mainNav {
  
  background: #1f3763;
  color: white;
}
#B {
  background: #cee3fe;
}
#mainNav, #B {
  padding: 3rem;
}
svg rect {
  fill: #ffffff;
}
#mainNav .navbar-brand {
    color: white;
}
#mainNav .navbar-nav .nav-item .nav-link {
    color: white;
}
</style>